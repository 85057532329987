<template>
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" version="1.1"><g fill-rule="evenodd">
        <path d="M24 13L2 13L2 11L24 11L24 13Z" />
        <path d="M6.61667 6L8 7.25423L2.76478 12L8 16.7458L6.61667 18L-5.24538e-07 12L6.61667 6Z" />
      </g>
    </svg>
</template>

<script>
export default {
  name: 'arrow_left',
}
</script>
